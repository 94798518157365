.app-container {
  font-family: Roboto, helvetica, sans-serif;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  max-width: 800px;
  text-align: center;
  width: 80vw;
}
.app-header {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}
.display {
  display: flex;
  width: inherit !important;
}
.hidden {
  display: none !important;
}
.app-h4-padding-top {
  padding-top: 0.75em;
}
#welcome-sub-text-2 {
  padding-top: 3rem;
}
.footer {
  margin-top: 4rem;
}
.app-header-logo {
  background-color: white;
  background-image: url(/aw_logo.png);
  background-position-x: 20%;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 150px auto;
  display: block !important;
  height: 66px;
  min-width: 100vw;
}
.app-card-style {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.modal-select-style {
  padding-top: 17px;
}
.app-modal-card-style {
  height: fit-content;
  left: 50%;
  margin-left: -400px;
  margin-top: -250px;
  overflow: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  width: 800px;
}
.notification-message-container {
  background: #d3d3d3;
  border-color: #cec8c8;
  border-style: solid;
  border-width: thin;
  margin: 24px 0px;
}
.notification-message-container-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}
.notification-message-container-info {
  background-color: #cce5ff;
  border-color: #b8daff;
  color: #004085;
}
.notification-message-container-warn {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}
.notification-message-container-error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
.notification-message-icon-container {
  margin-top: 0.5em !important;
}
.notification-message-icon {
  font-size: 4em !important;
}
.error-message-icon {
  font-size: 6em !important;
}
.notification-message-icon-success {
  color: #155724;
}
.notification-message-icon-info {
  color: #004085;
}
.notification-message-icon-warn {
  color: #856404;
}
.notification-message-icon-error {
  color: #721c24;
}
.notification-message-text-container {
  margin: 1em 0px;
}
@media screen and (max-width: 600px) {
  .app-modal-card-style {
    margin-left: -50%;
    overflow-x: auto;
    overflow-y: auto;
    width: 100vw;
  }
  .app-container {
    margin-top: 5em;
    width: 100%;
  }
}
@media screen and (max-width: 760px) {
  .notification-message-container {
    margin: 0px;
  }
  .notification-message-icon-container .notification-message-icon {
    font-size: 3em !important;
    margin-top: 20px;
  }
}
.enable-horizontal-scroll {
  overflow-x: auto;
}
.app-modal-detail {
  min-width: 400px;
  overflow: auto;
}
.MuiDrawer-paper {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  outline: 0;
  overflow-y: auto;
  top: 0;
  width: 240px;
  z-index: 1200;
}
.MuiExpansionPanelDetails-root {
  display: flow-root;
  padding: 1px;
}
.MuiToolbar-regular {
  background-color: #ffffff;
}
.claims-app-column {
  flex-basis: "33.33%";
}
.MuiTablePagination-root:last-child {
  border-bottom: 0px;
}
.loader {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-top: 3px solid #42a5f5;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
  width: 75px;
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    border-top-color: #ec407a;
    transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    border-top-color: #ec407a;
    transform: rotate(360deg);
  }
}
